import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import React, { Fragment } from 'react';

import { sectionStyle } from 'holded/lib/styles';
import { Page } from 'holded/modules/cms/domain/page';

//TODO export theme
const THEME: Record<string, Record<string, string>> = {
  dark: {
    background: 'bg-gray-700',
    text: 'text-white',
    border: 'border',
    borderColor: 'border-white focus:outline-white ring-white',
    paddings: 'px-2 py-1',
    rounded: 'rounded-md',
    justify: 'justify-start',
  },
  light: {
    background: 'bg-white',
    text: 'text-gray-dark',
    borderColor: 'gray-dark',
    paddings: 'px-2 py-1',
    rounded: 'rounded-md',
    border: 'border',
    justify: 'justify-start',
  },
  soft: {
    background: 'bg-white',
    paddings: 'px-4 py-2',
    borderColor: 'border-transparent',
    rounded: 'rounded-b-md',
    justify: 'justify-end',
    border: '',
    text: '',
  },
  darkMode: {
    background: 'bg-[#111827]',
    paddings: 'px-4 py-2',
    borderColor: 'border-transparent',
    rounded: 'rounded',
    justify: 'justify-end',
    border: '',
    text: 'text-white',
  },
};

type Props = {
  theme: string;
  pageData: Page;
};

//TODO import language translations from Strapi
const LOCALES: Record<string, Record<string, string>> = {
  es: { es: 'Español', en: 'Inglés' },
  en: { es: 'Spanish', en: 'English' },
};

const getLocaleName = (locale: string | undefined) => {
  if (!locale) return LOCALES['en']['en'];
  return LOCALES[locale][locale];
};

const LocaleSwitch = ({ theme, pageData }: Props) => {
  const currentLocale = pageData?.attributes?.locale ?? 'en';

  return (
    <div className="relative">
      <div className={`rounded-md z-10 ${THEME[theme]?.border} ${THEME[theme]?.borderColor}`}>
        <div className="relative">
          <Menu as={'div'} className="relative">
            {({ open }) => (
              <div className={`${open && 'shadow-xl'}`}>
                <Menu.Button
                  as={'button'}
                  className={`group rounded-md flex justify-start text-base items-center font-normal ${
                    open && sectionStyle.textColor.default
                  } focus:outline-none ${THEME[theme]?.paddings} w-full`}
                >
                  <span className={`flex ${THEME[theme]?.justify} ${THEME[theme]?.text || 'text-gray-400'} w-full`}>
                    {getLocaleName(pageData?.attributes?.locale)}
                  </span>

                  {!open ? (
                    <ChevronDownIcon
                      className={`${theme === 'soft' || theme === 'darkMode' ? 'h-7 w-7' : 'h-5 w-5'} ml-1 ${
                        theme == 'darkMode' ? 'text-gray' : 'text-blue'
                      }`}
                      aria-hidden="true"
                    />
                  ) : (
                    <ChevronUpIcon
                      className={`${theme === 'soft' || theme === 'darkMode' ? 'h-7 w-7' : 'h-5 w-5'} ml-1 ${
                        theme == 'darkMode' ? 'text-gray' : 'text-blue'
                      }`}
                      aria-hidden="true"
                    />
                  )}
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-out duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Menu.Items>
                    <ul
                      className={`${theme == 'light' && 'border absolute bg-white'} ${THEME[theme]?.paddings} ${
                        theme == 'soft' && 'absolute bg-white'
                      }
                      ${theme == 'darkMode' && 'border border-[#374151] absolute bg-[#111827]'} w-full pl-6 ${
                        THEME[theme]?.rounded
                      }`}
                    >
                      <Menu.Item>
                        <>
                          {Object.keys(LOCALES).map((locale) => {
                            if (locale !== currentLocale) {
                              const alternatePath = pageData?.localizationSlugs?.find(
                                (path) => path.locale === locale,
                              )?.slug;
                              return (
                                <li key={locale}>
                                  <a
                                    href={alternatePath ?? '/'}
                                    role={'link'}
                                    className={`${
                                      theme === 'darkMode' ? 'hover:text-[#9CA3AF]' : 'hover:text-[#6B7280]'
                                    } ${THEME[theme]?.text}`}
                                  >
                                    <span className={`flex justify-start w-full`}>
                                      {LOCALES[currentLocale][locale]}
                                    </span>
                                  </a>
                                </li>
                              );
                            }
                          })}
                        </>
                      </Menu.Item>
                    </ul>
                  </Menu.Items>
                </Transition>
              </div>
            )}
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default LocaleSwitch;
